<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('carouselManagement.order')" prop="order">
          <el-input
            @input="
              (res) => {
                this.ruleForm.order = +res;
              }
            "
            @mousewheel.native.prevent  
            @DOMMouseScroll.native.prevent 
            type="number"
            placeholder="정렬순이 높은 건이 우선 노출됩니다."
            v-model="ruleForm.order"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('carouselManagement.link')" prop="link">
          <el-input
            v-model="ruleForm.link"
            :placeholder="$t('carouselManagement.urlRule')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('carouselManagement.image')" prop="image">
          <upload-img
            :limitMax="1"
            :file-list="ruleForm.image"
            @changeImgList="changeImgList"
          ></upload-img>
        </el-form-item>
        <el-form-item
          :label="$t('carouselManagement.h5_image')"
          prop="h5_image"
        >
          <upload-img
            :limitMax="1"
            :file-list="ruleForm.h5_image"
            @changeImgList="changeImgList1"
          ></upload-img>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import uploadImg from "@/components/uploadImg";
import { carouselDetail, carouselAdd, carouselChange } from "@/api/index";
export default {
  name: "carouselEdit",
  data() {
    var validatePass = (rule, value, callback) => {
      const reg = /http[s]{0,1}:\/\/([\w.]+\/?)\S*/;
      if (reg.test(value) || value === "" || value === null) {
        callback();
      } else {
        callback(new Error(this.$t("carouselManagement").urlRule));
      }
    };
    return {
      ruleForm: {
        order: 0,
        link: "",
        image: "",
        h5_image: "",
      },
      rules: {
        order: [
          {
            required: true,
            message: "정렬순이 높은 건이 우선 노출됩니다.",
            trigger: "blur",
          },
        ],
        link: [
          {
            validator: validatePass,
            message: this.$t("carouselManagement").urlRule,
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            message: this.$t("carouselManagement").image,
            trigger: "blur",
          },
        ],
        h5_image: [
          {
            required: true,
            message: this.$t("carouselManagement").h5_image,
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: { uploadImg },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  methods: {
    changeImgList(arr) {
      console.log(arr)
      if (arr.length === 0) {
        this.ruleForm.image = "";
      } else {
        this.ruleForm.image = arr[0];
      }
    },
    changeImgList1(arr) {
      if (arr.length === 0) {
        this.ruleForm.h5_image = "";
      } else {
        this.ruleForm.h5_image = arr[0];
      }
    },
    async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.ruleForm.link === "") {
        this.ruleForm.link = null;
      }
      if (this.id === undefined) {
        await carouselAdd(this.ruleForm);
      } else {
        delete this.ruleForm.create_time;
        delete this.ruleForm.update_time;
        await carouselChange(this.ruleForm);
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await carouselDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
